.metrics-selector-container {
  text-align: left;
}

h4 {
  margin-top: auto;
}

select {
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
}

.label {
  margin-top: 10px;
}