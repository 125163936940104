@media (max-width: 859.99px) {
  .collection-container.disabled {
    display: none;
  }
  .collection-container.active {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
  }
}

@media (min-width: 860px) {
  .sector-explorer-container {
    display: flex;
    flex-direction: row;
  }
  .collection-container.disabled {
    border-right: dotted 2px #CCC;
  }
}

@media (min-width: 860px) and (max-width: 1279.99px) {
  .collection-container {
    display: none;
  }
  .collection-container:nth-last-child(1),
  .collection-container:nth-last-child(2) {
    display: block;
  }
}

@media (min-width: 1280px) and (max-width: 1719.99px) {
  .collection-container {
    display: none;
  }
  .collection-container:nth-last-child(1),
  .collection-container:nth-last-child(2),
  .collection-container:nth-last-child(3) {
    display: block;
  }
}