.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content {
  padding-bottom: 70px;
}

nav {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  background-color: #F6F6F6;
}

nav a {
  display: inline-block;
  padding: 15px;
  background-color: #5999FB;
  border-radius: 5px;
  color: #FFF;
  margin: 10px;
  text-decoration: none;
}