@media (max-width: 1023.99px) {
  .metrics-selector-container {
    padding: 40px;
    background-color: #f0f0f0;
  }
}

@media (min-width: 1024px) {
  .chart-controls-container {
    display: flex;
  }

  .comparison-chart-container {
    width: 70%;
  }

  .metrics-selector-container {
    width: 30%;
    padding: 0 40px;
  }
}