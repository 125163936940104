.collection-container {
  margin: 50px 0;
  padding: 0 50px;
}
.parent-node-label {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.collection-container.disabled .parent-node-label {
  opacity: 0.3;
}
.parent-node-label .svg-inline--fa {
  position: relative;
  top: 7px;
  width: 30px;
  height: 30px;
}
.collection-container.active .svg-inline--fa {
  cursor: pointer;
}
.parent-node-label h1 {
  margin-top: auto;
  margin-bottom: 40px;
}
.card-collection {
  display: flex;
  flex-direction: column;
  gap: 50px;
}