.card-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
  max-width: 400px;
  min-width: 320px;
  filter: drop-shadow(3px 3px 5px #CCC);
  cursor: default;
}
.card-container.disabled {
  opacity: 0.3;
}
.card-image, .card-body {
  flex: 1;
}
.card-image {
  background-size: cover;
  min-height: 250px;
}
.card-body {
  background-color: #FFF;
  padding: 30px;
}
.card-content {
  text-align: center;
  margin-bottom: 30px;
}
.card-content h2 {
  margin-top: auto;
}
.card-actions {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.card-action-button {
  flex: 1;
  text-align: center;
  color: #282c34;
}
.card-container.active .card-action-button {
  cursor: pointer;
}
.card-action-button.selected {
  color: #5999FB;
}
svg.svg-inline--fa {
  width: 50px;
  height: 50px;
}
.card-action-label {
  margin-top: 10px;
}