table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  background-color: #ddd;
}

tbody tr:nth-child(2n) {
  background-color: #f6f6f6;
}

td ul {
  list-style-type: none;
  padding: 0;
}

td ul li {
  margin: 10px auto;
}

@media (max-width: 479.99px) {
  th {
    padding: 10px;
  }
  td {
    padding: 10px;
  }
}

@media (min-width: 480px) {
  th {
    padding: 20px 50px;
  }
  td {
    padding: 20px 30px;
  }
}

@media (max-width: 680px) {
  th[data-key="desc"],
  td[data-key="desc"] {
    display: none;
  }
}